// In your staticData.js slice file
import { createSlice } from '@reduxjs/toolkit';

const staticDataSlice = createSlice({
  name: 'staticData',
  initialState :  {
    // selectedHUNumber: "",   
    // selectedBatchName: "",  
    AllCustomers : [], 

    },
  reducers: {
   
    // Add this reducer to save customers
    saveCustomers(state, action) {
      state.AllCustomers = action.payload;
    },

    


  },
});

// Export the action
export const {
  setSelectedHUNumber,
  
} = staticDataSlice.actions;


export {staticDataSlice};

// const [initialLoad, setInitialLoad] = useState(false);

