import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import createApiSlice from "../Slices/Common/CommoCreateSlice";
import config from '../../config';
import {  ApiEndPoint } from "../../service/service"
import STATUSES from "../Statuses";


const serviceURL = config.SERVICE_TRACEABILITY_URL;

 

// Get all getBatchData
export const getBatchData = createAsyncThunk( "getBatchData/get", async ( data ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/towel-Batch-Trace`,data,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const getBatchDataSlice = createApiSlice('getBatchData', getBatchData , true);




const getTowelCustomerDataSlice = createSlice({
    name : "getTowelCustomerData",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null,
        hasFetchedTowelCustomerData: false, 
    },
    reducers : {
        resetGetTowelCustomerDataState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getTowelCustomerData.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getTowelCustomerData.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
            state.hasFetchedTowelCustomerData = true;
        })

        .addCase(getTowelCustomerData.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
 
// Get all Towel Customer list
export const getTowelCustomerData = createAsyncThunk( "getTowelCustomerData/get", async ( {id = null} ,{ rejectWithValue , getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}${ApiEndPoint?.TOWER_CUSTOMER_LIST}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const selectHasFetchedTowelCustomerData = (state) => state.TowelCustomerData.hasFetchedTowelCustomerData;

// const getTowelCustomerDataSlice = createApiSlice('getTowelCustomerData', getTowelCustomerData , true);


// Get all Customer PO and SO list
export const getCustomerPoSo = createAsyncThunk( "getCustomerPoSoData/get", async ( {data} ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/customer-so-header`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getCustomerPoSoSlice = createApiSlice('getCustomerPoSoData', getCustomerPoSo , true);

// Get all order data form PO and SO number 

export const getOrderData = createAsyncThunk( "getallOrderData/get", async ( {data} ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/towel-SO_Tracking`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getOrderDataSlice = createApiSlice('getallOrderData', getOrderData , true);

// Get all SKU form the PO and SO number
export const getSKUData = createAsyncThunk( "getallSKUData/get", async ( {data} ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        // const dataToSend = {IV_VBELN : data}
        // console.log(dataToSend)

        const responce = await axios.post(`${serviceURL}/towel/towelCustomerSOItems`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getSKUDataSlice = createApiSlice('getallSKUData', getSKUData , true);

// Get all HU form the PO and SO number and SKU number
export const getHUData = createAsyncThunk( "getallHUData/get", async ( {data} ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/towel-so-sku-hu`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getHUDataSlice = createApiSlice('getallHUData', getHUData , true);


// Get all All Batch Names by PO and SO number and SKU number and CSP - PROCESS , WEAVING
export const getAllBatchNames = createAsyncThunk( "getAllBatchName/get", async ( {data} ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/towel-Batches-against-SO-SKU`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});





const getAllBatchNamesSlice = createApiSlice('getAllBatchName', getAllBatchNames , true);



// Get all All Container by SO -
export const getAllContainerbySO = createAsyncThunk( "getAllContainerbySo/get", async ( {data} ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/towelContainerDetails`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAllContainerbySOSlice = createApiSlice('getAllContainerbySo', getAllContainerbySO , true);


// Get all All CustomerData by customer code -
export const getAllCustomerDataByCode = createAsyncThunk( "getAllCustomerDataByCode/get", async ( data ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        console.log(data)

        const responce = await axios.post(`${serviceURL}/towel/towelCustomerData`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAllCustomerDataByCodeSlice = createApiSlice('getAllCustomerDataByCode', getAllCustomerDataByCode , true);


// Get all All CustomerData by customer code -
export const getAllCustomerDataBreakUpByCode = createAsyncThunk( "getAllCustomerDataBreakUpByCode/get", async ( data ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};


        const responce = await axios.post(`${serviceURL}/towel/towelCustomerData`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


// Get all All CustomerData Complaint by customer code -
export const getAllCustomerDataComplaint = createAsyncThunk( "getAllCustomerDataComplaint/get", async ( data ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/customer-complaint`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


// Get all All CustomerData OpenSoDetails by customer code -
export const getAllCustomerDataOpenSoDetails = createAsyncThunk( "getAllCustomerDataOpenSoDetails/get", async ( data ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/towel/open_so_details`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


// Get all All /api/v1/towel/towelContainerDetails by customer code -
export const getTowelContainerDetails = createAsyncThunk( "getTowelContainerDetail/get", async ( data ,{ rejectWithValue , getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {  number : data.number,
                sealine : data.sealine , 
                type : data.type , 
                forceUpdate : data.forceUpdate,
                route : data.route,
                ais : data.ais}
        };

        const responce = await axios.get(`${serviceURL}/towel/trackContainer`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// HASU4274270







export {
    getBatchDataSlice,
    getTowelCustomerDataSlice,
    getCustomerPoSoSlice,
    getSKUDataSlice,
    getHUDataSlice,
    getOrderDataSlice,
    getAllBatchNamesSlice,
    getAllContainerbySOSlice,
    getAllCustomerDataByCodeSlice
}

export const {resetState : resetGetBatchData} = getBatchDataSlice.actions;
export const {resetState : resetGetTowelCustomerData} = getTowelCustomerDataSlice.actions;
export const {resetState : resetGetCustomerPoSo} = getCustomerPoSoSlice.actions;
export const {resetState : resetGetSKUData} = getSKUDataSlice.actions;
export const {resetState : resetGetHUData} = getHUDataSlice.actions;
export const {resetState : resetGetOrderData} = getOrderDataSlice.actions;
export const {resetState : resetGetAllBatchNames} = getAllBatchNamesSlice.actions;
export const {resetState : resetGetAllContainerbySO} = getAllContainerbySOSlice.actions;
export const {resetState : resetGetAllCustomerData} = getAllCustomerDataByCodeSlice.actions;



