// In your liveAPI.js slice file
import { createSlice } from '@reduxjs/toolkit';

const liveAPISlice = createSlice({
  name: 'liveAPI',
  initialState :  {
    // selectedHUNumber: "",   
    // selectedBatchName: "",  
    AllCustomers : [], 

    traceability : {
      selectedCustomer : null,
      selectedTrackBy : null,
      fromDate : "",
      toDate : "",
      soNumbersList: [],
      soNumber: null,
      soSKUnumbersList: [],
      soSKUnumber: null,
      HUnumbersList: [], 
      HUnumber: null,
      batchNumberList: [],
      batchNumber: null,
      treeData : null,
      initialLoad : false
    },
    orderStatus : {
      selectedCustomer : null,
      selectedTrackBy : null,
      fromDate : "",
      toDate : "",
      soNumbersList: [],
      soNumber: null,
      soSKUnumbersList: [],
      soSKUnumber: null,
      HUnumbersList: [], 
      HUnumber: null,
      selectedContainer : []
    },
    containerTracing :{
      selectedCustomer : null,
      selectedTrackBy : null,
      fromDate : "",
      toDate : "",
      soNumbersList: [],
      soNumber: null,
      soSKUnumbersList: [],
      soSKUnumber: null,
      HUnumbersList: [], 
      HUnumber: null,
      selectedContainer : ["HASU4274270"]
    }
  },
  reducers: {
    // other reducers

    // Add this reducer to update the selected HU number
    // setSelectedHUNumber(state, action) {
    //   // If a batch name is present, clear it
    //   if (state.selectedBatchName) {
    //     state.selectedBatchName = "";
    //   }
    //   state.selectedHUNumber = action.payload;
    // },

    // setSelectedBatchName(state, action) {
    //   // If an HU number is present, clear it
    //   if (state.selectedHUNumber) {
    //     state.selectedHUNumber = "";
    //   }
    //   state.selectedBatchName = action.payload;
    // },

    // Add this reducer to save customers
    saveCustomers(state, action) {
      state.AllCustomers = action.payload;
    },

    // Set Selected customer 
    setSelectedCustomer(state, action) {
      const { section, customer } = action.payload; // Destructure section and customer
      if (state[section]) {
        state[section].selectedCustomer = customer; // Update selectedCustomer for the section
      }
    },

    // Set SelectedTrackBy 
    setSelectedTrackBy(state, action) {
      const { section, item } = action.payload; 
      if (state[section]) {
        state[section].selectedTrackBy = item; 
      }
    },

    // Set fromDate
    setFromDate(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].fromDate = item;
      }
    },

    // Reducer to set toDate
    setToDate(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].toDate = item;
      }
    },

    // Set SO numbers list
    setSoNumbersList(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].soNumbersList = item;
      }
    },

    // Set SO numbers
    setSoNumber(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].soNumber = item;
      }
    },


    // Set SO SKUs list
    setSoSKUnumbersList(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].soSKUnumbersList = item;
      }
    },

    // Set SO SKU number
    setSoSKUnumber(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].soSKUnumber = item;
      }
    },

    // Set HUnumbersList
    setHUnumbersList(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].HUnumbersList = item;
      }
    },

    // Set batch number list
    setBatchNumberList(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].batchNumberList = item;
      }
    },

    // Set HUnumber
    setHUnumber(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        // Clear batchNumber if a HUnumber is set
        state[section].batchNumber = null;
        state[section].HUnumber = item;
      }
    },
    
    // Set batch number
    setBatchNumber(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        // Clear HUnumber if a batchNumber is set
        state[section].HUnumber = null;
        state[section].batchNumber = item;
      }
    },

    setTreeData(state, action) {
      // const { item } = action.payload;
      if (state.traceability) {
        state.traceability.treeData = action.payload;
      }
    },

    setInitialLoad(state , action) {
      if (state.traceability){
        state.traceability.initialLoad = action.payload;
      }
    },

    setSelectedContainer(state, action) {
      const { section, item } = action.payload;
      if (state[section]) {
        state[section].selectedContainer = item;
      }
    },


  },
});

// Export the action
export const {
  setSelectedHUNumber,
  setSelectedBatchName,
  saveCustomers,
  setSelectedCustomer,
  setSelectedTrackBy,
  setFromDate,
  setToDate,
  setSoNumbersList,
  setSoNumber,
  setSoSKUnumbersList,
  setSoSKUnumber,
  setHUnumbersList,
  setHUnumber,
  setBatchNumberList,
  setBatchNumber,
  setTreeData,
  setInitialLoad,
  setSelectedContainer
} = liveAPISlice.actions;

export {liveAPISlice};

// const [initialLoad, setInitialLoad] = useState(false);

