import React from 'react';
import Select from 'react-select';
import '../../index.css'; 

export const InputBox = ({ labelName, DataType, labelValue, handleFun ,value , error}) => {
  return (
    <label className="inpBox">
      <input type={DataType} placeholder="&nbsp;" name={labelValue} value={value} onChange={handleFun} required/>
      <span className="label">{labelName}</span>
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};


// export const InputBox = ({ labelName, DataType, labelValue, handleFun,value , error }) => {
//   return (
//     <label className="inpBox">
//       <input
//         type={DataType}
//         placeholder="&nbsp;"
//         name={labelValue}
//         onChange={handleFun}
//         required
//         {...(DataType === "file" ? {} : { value })} // Handle files separately
//       />
//       <span className="label">{labelName}</span>
//       <label className="focus-bg"></label>
//       {error && <p className="error_message">* {error}</p>}
//     </label>
//   );
// };

export const TextAreaBox = ({ labelName, DataType, labelValue, handleFun , value , error }) => {
  return (
    <label className="inpBox">
      <textarea type={DataType} placeholder="&nbsp;" name={labelValue} value={value} onChange={handleFun} required/>
      <span className="label">{labelName}</span>
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};

export const DropdownBox = ({ labelName, options, selectedValue, handleSelect, name , error , showSpan}) => {
  return (
    <label className="inpBox">
      <select name={name} value={selectedValue} onChange={handleSelect} required>
        <option value="" >{showSpan && labelName}</option>
        {options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {!showSpan && <span className="label">{labelName}</span>}
      
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};


export const CheckBox = ({ labelName, name, checked, handleChange, error }) => {
  return (
    <label className="inpBox checkboxWrapper">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      <span className="label">{labelName}</span>
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};




  export const SearchableDropdown = ({
    options,
    selectedValue,
    handleSelect,
    labelField ,
    valueField ,
    placeholderData,
  }) => {

    // Check if options are strings, if so, convert them directly to { value, label } format
    const selectOptions = Array.isArray(options) && typeof options[0] !== "object"
      ? options.map(option => ({ value: option, label: option }))
      : options.map(option => ({
          value: option[valueField],
          label: option[labelField],
          ...option, // Include other fields if they exist
        }));
      // console.log(selectOptions);


      // Custom styles for conditional background color
       // Custom styles for conditional styling
      const customStyles = {
        control: (base, state) => ({
          ...base,
          borderColor: state.isFocused ? "blue" : base.borderColor, // Border color only on focus
          "&:hover": {
            borderColor: "blue",
          },
          fontSize: "12px", // Match your CSS font size
          width: "400px", // Match your CSS width
          margin: "10px 0", // Match your CSS margin
        }),
        singleValue: (base, { data }) => ({
          ...base,
          fontWeight: selectedValue ? "500" : base.fontWeight, // Bold font if value is selected
          color: selectedValue ? "blue" : base.color, // Blue font color if value is selected
        }),
        menu: (base) => ({
          ...base,
          zIndex: 9999, // Match your CSS z-index
        }),
        option: (base) => ({
          ...base,
          fontSize: "11px", // Match your CSS font size for options
        }),
      };

    return (
      <div>
        <Select
          options={selectOptions}
          value={selectedValue}
          onChange={handleSelect}
          placeholder={placeholderData}
          isSearchable
          className="filter_select_multi"
          classNamePrefix="filter-select"
          styles={customStyles} // Apply conditional styles
        />
      </div>
    );
  };


  